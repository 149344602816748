html, body {
  width: 100vw;
  height: 100%; }

body {
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

body #root {
  height: 100%;
  width: 100%; }

.fill-view-port {
  width: 100%;
  height: 100%; }

body::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0); }

body::-webkit-scrollbar {
  width: 6px;
  margin: 0 2px;
  background-color: rgba(0, 0, 0, 0); }

body::-webkit-scrollbar-thumb {
  background: #686868;
  padding: 0 2px;
  border-radius: 10px;
  background-clip: content-box; }
