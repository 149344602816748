@import "../../common";

.blobs {
  position: absolute;
  top: -15%;
  right: 12%;
  @media (max-width: $phone-only) {
    top: -40%;
    right: -10%; } }

.about-me {
  position: relative;
  padding: 3.2rem 5rem 1rem;
  width: 75%;
  @media (max-width: $phone-only) {
    padding: 1rem;
    width: 92% !important; }
  @media (min-width: $big-desktop-up) {
    width: 70%; }
  @media (min-width: 1200px) and (max-width: $desktop) {
    width: 76%;
    padding: 2.8rem 4.5rem 0; } }

.title-backdrop {
  background-image: url("abstract.jpg");
  background-size: cover;
  width: fit-content;
  height: fit-content; }

.title {
  font-family: 'Baloo 2', cursive;
  font-size: 5.3rem;
  color: #90797E;
  background-color: transparent;
  margin: 0;
  line-height: 1;
  @media (max-width: $sm-phone) {
    font-size: 3rem !important; }
  @media (max-width: $phone-only) {
    font-size: 3.4rem;
    line-height: 1.1; }
  @media (min-width: $big-desktop-up) {
    font-size: 5.8rem; }
  @media (min-width: 1200px) and (max-width: $desktop) {
    font-size: 4.8rem; } }

.who-is-fabian {
  font-size: 2.55rem;
  margin: 0 0 2rem;
  color: #4b4b4b;
  font-weight: 300;
  line-height: 145%;
  @media (max-width: $sm-phone) {
    font-size: 1.55rem !important; }
  @media (max-width: $phone-only) {
    font-size: 1.66rem;
    font-weight: 400;
    color: #797979;
    margin: 0 0 1.5rem;
    line-height: 138%; }
  @media (min-width: $big-desktop-up) {
    font-size: 2.8rem; }
  @media (min-width: 1200px) and (max-width: $desktop) {
    font-size: 2.3rem;
    margin: 0 0 1.7rem; } }

.who-is-fabian strong {
  font-weight: 600;
  color: #686868;
  @media (max-width: $phone-only) {
    color: #292929; } }

.who-is-fabian a {
  text-decoration: none;
  color: #A8DADC; }

.work {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;
  margin-bottom: 20px;
  @media (max-width: $phone-only) {
    grid-gap: 18px; }
  @media (min-width: 1200px) and (max-width: $desktop) {
    grid-gap: 20px; } }

.work a {
  width: 300px;
  font-size: 1.4rem;
  text-decoration: none;
  color: #525252;
  font-weight: 600;
  background: white;
  border: 1px solid rgba(166, 139, 145, 0.15);
  border-radius: 6px;
  box-shadow: 0 5px 20px rgba(151, 129, 134, 0.18);
  padding: 25px 22px;
  transition: box-shadow .4s;

  &.-resume {
    i {
      color: #f6cd5d; } }

  &.-dribbble {
    i {
      color: #ea4c89; } }

  &.-linked-in {
    i {
      color: #0077b5; } }

  &.-showcase {
    i {
      color: #8e44ad; } }

  &.-email {
    i {
      color: #d93025; } }

  @media (max-width: $sm-phone) {
    width: 240px !important; }
  @media (max-width: $phone-only) {
    width: 300px;
    font-size: 1.3rem;
    padding: 20px; }
  @media (min-width: 1200px) and (max-width: $desktop) {
    padding: 24px 22px; } }

.work a:hover {
  box-shadow: 0 4px 55px rgba(151, 129, 134, 0.3); }

.work a i {
  margin-right: 4px;
  @media (max-width: $phone-only) {
    margin-right: 2px; } }
